<template>
  <layout >
    <!-- 登录-->
    <div>
      <div class="title">
        神笔电商服务平台
      </div>
      <el-form ref="loginForm" :model="formData" >
        <el-form-item :rules="[{required:true,message:'请输入账号'}]" prop="account">
          <div class="inputBox">
            <el-input v-model="formData.account" placeholder="请输入账号" class="input" @keyup.enter.native="login">
              <img src="../../assets/userIcon.png" class="icon" slot="prepend" >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item :rules="[{required:true,message:'请输入密码'}]" prop="password">
          <div class="inputBox">
            <el-input type="password" v-model="formData.password" placeholder="请输入密码" class="input" @keyup.enter.native="login">
              <img src="../../assets/passwordIcon.png" class="icon" slot="prepend" >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item :rules="[{required:true,message:'请输入验证码'}]" prop="captcha">
          <div style="display: flex;align-items: center">
            <div class="inputBox">
              <el-input v-model="formData.captcha" class="input" style="margin-right: 4px" placeholder="请输入验证码" @keyup.enter.native="login" >
                <img src="../../assets/codeIcon.png" class="icon" slot="prepend" >
              </el-input>
            </div>
            <img :src="img" @click="getImg" style="height: 48px;width:160px;border-radius: 8px">
          </div>
        </el-form-item>
        <el-form class="login">
          <el-button @click="login" style="width: 100%">登录</el-button>
        </el-form>
      </el-form>
      <div class="more">
<!--        <span class="textBut" style="margin-right: 20px" @click="goRegister">免费注册</span>-->
        <!--                               <span class="textBut" @click="status =3">忘记密码</span>-->
      </div>

    </div>
    <!-- 忘记密码-->
<!--    <div v-if="status==3" :key="3">-->

<!--      <div class="title">-->
<!--        神笔电商服务平台-->
<!--      </div>-->
<!--      <el-form ref="formData" :model="formData" >-->
<!--        <el-form-item>-->
<!--          <div class="inputBox">-->
<!--            <el-input v-model="formData.account" placeholder="请输入账号" class="input" >-->
<!--              <img src="../../assets/userIcon.png" class="icon" slot="prepend" >-->
<!--            </el-input>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item >-->
<!--          <div class="inputBox">-->
<!--            <el-input type="password" v-model="formData.password" placeholder="请输入密码" class="input">-->
<!--              <img src="../../assets/passwordIcon.png" class="icon" slot="prepend" >-->
<!--            </el-input>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <div style="display: flex;align-items: center">-->
<!--            <div class="inputBox">-->
<!--              <el-input v-model="formData.captcha" class="input" style="margin-right: 4px" placeholder="请输入验证码">-->
<!--                <img src="../../assets/codeIcon.png" class="icon" slot="prepend" >-->
<!--              </el-input>-->
<!--            </div>-->
<!--            <div style="height: 48px;width:160px;border-radius: 8px;text-align: center;line-height: 48px">-->
<!--              {{ time?time+'S':'发送验证码'}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form class="login">-->
<!--          <el-button @click="login" style="width: 100%" :loading="loginLoading">登录</el-button>-->
<!--        </el-form>-->
<!--      </el-form>-->
<!--      <div class="more">-->
<!--        <span class="textBut" style="margin-right: 20px" @click="status =2">免费注册</span>-->
<!--        <span class="textBut" @click="status =3">忘记密码</span>-->
<!--      </div>-->


<!--    </div>-->
  </layout>
</template>

<script>
    const img = process.env.VUE_APP_API_BASE_URL + '/captcha.jpg';
    import {LOGIN,USERINFO} from '../../api/base'
    import Layout from './layout'
    import {validatePhone} from  '../../tools/validate'
    export default {
        name: "login",
        data() {
            const validator =  (rule, value, callback)=>{
              if(value==''){
                return  callback(new Error('请再次输入登录密码'))
              }
              console.log(this.registerForm.password)
              if(value !== this.registerForm.password){
                return callback(new Error('两次输入的登录密码不一样'))
              }else{
                return callback()
              }
            }
            return {
              validator,
                formData: {
                    account: '',
                    password: '',
                    captcha: '',
                },
                registerRules:{
                  name:[{required:true,message:'请输入您的名称',trigger:['change','blur']}],
                  account:[{required:true,validator:validatePhone,trigger:['change','blur']}],
                  verifyCode:[{required:true,message:'请输入验证码',trigger:['change','blur']}],
                },
                registerForm:{
                    name:'',
                    account: '',
                    password: '',
                    password1: '',
                    verifyCode: '',
                },
                time:0,
                disabled:false,
                status:1,
                img: '',
                show:false,
                loginLoading:false,

            }
        },
       methods: {
         goRegister(){
           this.$router.push('register')
         },
        getUserInfo(){
            this.$axios.get(USERINFO).then(res => {
                console.log(res)
                this.$store.commit('setting/setUserInfo', res.data)
                this.$router.push({path:'/pageIndex/sellerCenter'})

            }).catch(err => {
                this.$mes({message: err.msg, type: 'error'})

            })
        },
        login() {
           console.log(this.$refs.loginForm)
            this.$refs.loginForm.validate((validate)=>{
                if(this.formData.captcha.length != 5){
                  return false;
                }
                if(validate){
                    this.loginLoading = true;
                    this.$axios.post(LOGIN, this.formData).then(res => {
                        this.loginLoading = false;
                        console.log(res)
                        if (res.code == 200) {
                            this.$mes({message: res.msg});
                            this.$store.commit('setting/setUserToken', res.data.token_type + res.data.access_token)
                            this.$store.commit('setting/setAutoTime', res.data)
                            this.getUserInfo()
                        }
                    }).catch(err => {
                        this.$mes({message: err.msg, type: 'error'})
                        this.loginLoading = false;

                    })
                }
            })

        },
        getImg() {
            this.img = img + '?' + new Date().getTime()
            console.log(this.img)
        }
      },
      mounted() {
          this.getImg();
      },
      components: {
        Layout,
      }
    }
</script>

<style scoped lang="less">
    .beiAn{
        width: 100vw;
        position: absolute;
        bottom: 0;
        /*height: 100px;*/
        z-index: 9999;
    }
    @inputColor: #F9DFCD;
    .login{
        /deep/ .el-button--default{
            border-radius: 8px;
            background: linear-gradient(134.99deg, #FCB358 0%, #F9804D 100%);
            color: white;
        }
    }
    /deep/ input::-webkit-input-placeholder {
        color: #999;
    }
    /deep/ input:-moz-placeholder {
        color: #999;
    }
    /deep/ input:-ms-input-placeholder {
        color: #999;
    }
    .inputBox{
        display: flex;
        /deep/ .el-input-group__prepend{

            background-color: @inputColor;
        }
        .icon{
            width: 20px;
            height: 20px;
        }
    }
    .my-enter,.my-leave-to{
        opacity: 0;
        transform: translateX(80px);
    }
    /*进入和离开的动画时间段*/
    .my-enter-active,.my-leave-active{
        transition: all 0.5S ease
    }
    .more{
        padding-top: 20px;
        text-align: right;
        color: #999999;
        cursor: pointer;
        .textBut{

        }

    }
    .input{
        border: 2px solid #F9834E;
        border-radius: 8px;
        /deep/ .el-input__inner{
            background-color: @inputColor !important;
            border: 0px !important;

        }
        /deep/ .el-input-group__prepend{
            border: 0px !important;
        }


    }
    .card{
        border-radius: 20px;
        padding: 80px 50px;
        width: 500px;
        box-sizing: border-box;
        overflow: hidden;
        .title{
            font-size: 36px;
            line-height: 54px;
            text-align: center;
            margin-bottom: 24px;
        }
    }
</style>